@import './../../variables';

.container{
    padding: 0;
    display: flex;
    margin: 5px $sidesMargin;
    justify-content: space-between;
    gap: 100px;
    align-items: center;
    .logo-andes {
        width: 280px;
        height: 100px;
    }
    .nav{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 0 !important;
    }
    .navLinks{
        color: #D8D8D8 !important;
        width: auto;
        text-align: center;
        padding:  0px 5px !important;
        font-family: $font;
        font-size: 24px;
        font-weight: 500;
        text-decoration: none;
        margin-bottom: 5px;
        margin-top: 5px;
        &:hover{
            font-weight: 700;
        }
    }
}

@media (max-width: 1200px) {
    .container{
        gap: 100px;
        .nav{
            justify-content: space-between;
        }
    }
}

@media (max-width: 1120px) {
    .container{
        gap: 50px;
    }
}

@media (max-width: 990px) {
    .container{
        gap: 0px;
        .logo-andes{
            width: 140px;
            height: 50px;
        }
    }
}