.blog8 {
    background: linear-gradient(
    180deg,
    rgb(13, 0, 19) 0%,
    rgba(42, 0, 62, 1) 100%
);

#root:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("../../../img/mountains.png");
    background-repeat: repeat;
    background-position: 50% 0;
    background-size: cover;
}

-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
box-sizing: border-box;
}

.container-blog8 {
    margin: 100px auto; // Cambiamos los márgenes laterales a auto para centrar el contenedor.
    max-width: 80%; // Limitar el ancho máximo del contenedor para centrar el contenido.
    padding: 20px; // Añadir relleno alrededor del contenido.
    background-color: #00000062; // Fondo blanco semitransparente para mayor legibilidad.
    border-radius: 8px; // Borde redondeado para el contenedor.
    color: white;
}