@import './../../variables';

.footerConainer{
    padding: 0 $sidesMargin;
    padding-top: 15%;
    padding-bottom: 2%;
    background: linear-gradient(180deg,transparent 0%,rgba(11, 2, 16, 1) 30%, rgba(11, 2, 16, 1) 100%);
    .links{
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        align-items:start;
        gap: 15px;
        transition: .3s;
        font-family: $font;
        div{
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            width: auto;
            /* gap: 8px; */

            h3{
                color: white;
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 25px;
            }
            a,span{
                text-decoration: none;
                font-weight: 400;
                font-size: 18px;
                color: white;
                width: fit-content;
                padding: 5px 0;
                opacity: 0.6;
                margin: 5px;
                cursor: pointer;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }

    .copyright{
        /* aqui */
        margin-top: 50px;
        p{
            color: white;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            margin: 0;
        }
    }

}

@media (max-width: 900px) {
    .footerConainer{
        .links{
            div{
                h3{
                    font-size: 16px;
                }
                a,span{
                    font-size: 16px;
                }
            }
        }
    }
    .copyright{
        p{
            font-size: 14px;
        }
    }
}

@media (max-width: 800px) {
    .footerConainer{
        .links{
            flex-wrap: wrap;
            justify-content: space-between;
            div{
                padding: 10px;
                align-items: center;
                width: calc(50% - 7.5px);
                h3{
                    font-size: 16px;
                    margin-bottom: 5px;
                    text-align: center;
                }
                a,span{
                    font-size: 14px;
                    margin: 1px;
                    text-align: center;
                }
            }
        }
    }
    .copyright{
        p{
            font-size: 14px;
        }
    }
}