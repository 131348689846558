@import "./../../variables";

.benefits-card {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 33%;
  background-color: transparent;
  border: 0;
  height: 330px;
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    background-color: #3e055b8a;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.65);
    border-radius: 5px;
    opacity: 90%;
  }
  .benefits-card-title {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: $Color-cyan;
    margin-top: 15px;
  }
  .benefits-card-text {
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: $Color-white;
  }
  .benefits-text-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}

@media only screen and (max-width: 1100px) {
  .benefits-card {
    width: 49%;
    height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .benefits-card {
    width: 100%;
    height: 250px;
    .icon-container {
      height: 110px;
    }
    .benefits-card-title {
      font-size: 16px;
    }
    .benefits-card-text {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 500px) {
    .benefits-card{
        .benefits-card-text{
            font-size: 12px;
        }
    }
}