@import './../../variables';

.plansListContainer{
  margin: 20px $sidesMargin 5% $sidesMargin;
}

.gameListRow {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

.gameLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 5rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    .plansListContainer{
      .gameListRow{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        margin-left: 20%;
        margin-right: 20%;
      }
      .gameLogo{
        img{
          width: auto;
        }
      }
    }
  }


@media only screen and (max-width: 768px) {
  .plansListContainer{
    .gameListRow{
      display:flex;
      flex-direction: column;
      margin-left: 5%;
    }
    .gameLogo{
      img{
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .plansListContainer{
    .gameListRow{
      display:flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      margin-left: 5%;
    }
    .gameLogo{
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}