

.tyc{
    background: linear-gradient(180deg, rgb(13, 0, 19) 0%, rgba(42, 0, 62, 1) 100%);
    color: white;
    font-family: "Exo", sans-serif;;
    .tyc-container{
        background-image: url('../../img/mountains.png');
        background-repeat: repeat;
        background-position: 50% 0;
        background-size: cover;
        .tyc-logo {
            margin: 30px 60px;
        }
        .tyc-text{
            /* width: 950px; */
            width: 60%;
            margin: auto;
            h1 {
                margin: 50px 0 130px 0;
                text-align: center;
                font-size: 56px;
                font-weight: bold;
            }
            h2{
                margin-bottom: 10px;
                text-align: center;
                font-size: 34px;
                font-weight: bold;
            }
            p {
                margin: 60px auto;
                font-size: 20px;
                font-weight: 500;
                text-align: justify;
                text-wrap: pretty;
                a {
                    color: #00CEC9E5;
                    text-decoration: none;
                }
            }
        }
        .tyc-footer {
            background-color: white;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .tyc{
        .tyc-container{
            .tyc-logo{
                width: 200px;
            }
            .tyc-text{
                h1{
                    margin: 30px 0 50px 0;
                    font-size: 48px;
                }
                h2{
                    font-size: 30px;
                }
                p{
                    margin: 40px auto;
                    font-size: 18px;
                    a{

                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .tyc{
        .tyc-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            .tyc-logo{
                width: 300px;
                margin: 0;
            }
            .tyc-text{
                h1{
                    margin: 60px 0 50px 0;
                    font-size: 40px;
                }
                h2{
                    font-size: 22px;
                }
                p{
                    margin: 40px auto;
                    font-size: 16px;
                    a{

                    }
                }
            }
        }
    }
}
