@import './../../variables';

.ptero-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font;
    color: $Color-white;
    text-align: center;
    margin: 2vw $sidesMargin;
    .ptero-title{
        font-size: 45px;
        font-weight: 800;
        text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
    }
    .ptero-text{
        font-size: 20px;
        font-weight: 400;
        width: 80%;
    }
}

.img-section-background{
    background-image: url("../../img/stars_bg.png");
    .img-container{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 2vw $sidesMargin;
        font-family: $font;
        font-size: 24px;
        color:$Color-white;
        font-weight: 400;

        .img-text{
            text-align: left;
            padding: 20px;
            text-wrap: pretty;
        }
        .img-text2{
            text-align: right;
            padding: 20px;
            text-wrap: pretty;
        }
        .img-ptero{
            width: 100%;
            /* width: 540px;
            height: 300px; */
            transition: transform 150ms ease-out;
            &:hover{
                transform: scale(1.04);
            }
        }
    }
}

@media only screen and (min-width: 950px) and (max-width: 1260px){
    .ptero-container{
        img{
            width: 70%;
        }
        .ptero-title{
            font-size: 30px;
        }
        .ptero-text{
            font-size: 16px;
        }
    }

    .img-section-background{
        .img-container{
            font-size: 18px;
            .img-ptero{
                width: 100%;
                max-width: 400px;
                height: auto;
                /* width: 440px;
                height: 200px; */
            }
        }
    }
}



@media only screen and (max-width: 950px) {
    .ptero-container{
        img{
            width: 70%;
        }
        .ptero-title{
            font-size: 24px;
        }
        .ptero-text{
            font-size: 14px;
        }
    }
    .img-section-background{
        .img-container{
            font-size: 16px;
            .img-text{
                padding: 10px;
            }
            .img-text2{
                padding: 10px;
            }
            /* .img-ptero{
                width: 360px;
                height: 120px;
            } */
        }
    }
}

@media only screen and (max-width: 950px) {
    .ptero-container{
        img{
            width: 70%;
            margin-bottom: 20px;
        }
        .ptero-title{
            font-size: 24px;
        }
        .ptero-text{
            font-size: 18px;
            .ptero-delete{
                display: none;
            }
            text-align: center;
        }
    }
    .img-section-background{
        .img-container{
            display: flex;
            flex-direction: column;
            font-size: 18px;
            .img-text{
                padding: 10px;
                text-align: center;
                width: 80%;
            }
            .img-text2{
                padding: 10px;
                text-align: center;
                width: 80%;
            }
            .img-ptero{
                margin-bottom: 20px;
            }
        }
        .reverse {
            flex-direction: column-reverse;
        }
    }
}

@media only screen and (max-width: 550px) {
    .ptero-container{
        img{
            width: 70%;
            margin-bottom: 20px;
        }
        .ptero-title{
            font-size: 20px;
        }
        .ptero-text{
            font-size: 14px;
            .ptero-delete{
                display: none;
            }
            text-align: center;
        }
    }
    .img-section-background{
        .img-container{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            .img-text{
                padding: 10px;
                text-align: center;
                width: 80%;
            }
            .img-text2{
                padding: 10px;
                text-align: center;
                width: 80%;
            }
            .img-ptero{
                margin-bottom: 20px;
            }
        }
        .reverse {
            flex-direction: column-reverse;
        }
    }
}