@import './../../variables';

.FAQContainer{
    padding: 5vw 0;
    font-family: $font;
    color: white;
    margin: 0 $sidesMargin !important;
    margin-top: 15rem !important;
    .FAQtitle{
        text-align: center;
        font-size: 45px;
        font-weight: 800;
        margin-bottom: 50px;
    }
    .FAQAcordionsContainer{
        display: flex;
        flex-wrap: wrap;
    }
}


@media only screen and (max-width: 768px) {
     .FAQContainer{
        .FAQtitle{
            font-size: 30px;
        }
    }
}

/* aqui */
@media only screen and (max-width: 400px){
    .FAQContainer{
        margin-top: 100px !important;
    }
}