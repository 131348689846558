@import './../../variables';

.plans{
    padding: 5vw 0;
    position: relative;
    color: white;
    text-align: center;
    font-family: $font;
    margin-top: 5%;
    margin-bottom: 5%;
    p{
        font-size: 18px;
        font-weight: 400;
    }
    h2{
        padding-top: 3%;
        font-size: 3vw;
        font-weight: 800;
    }

    #dropdown-basic-button{
        position: absolute;
        right: $sidesMargin;
        top: 100px;
        z-index: 10;
        background-color: rgba(41, 3, 59, 0.5647058824);
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6%;
        min-width: 90px;

        &::after{
            transition: .2s;
            border: none;
            content: url('../../img/carousel/dropdown-closed-arrow.svg');
        }
        &.show::after{
            transition: .2s;
            transform: rotate(180deg);

        }

        &:hover{
            color: white;
        }
    }

    .dropdown-menu{
        background-color: rgba(41, 3, 59, 0.5647058824);
        min-width: 6%;
        .dropdown-item{
            color: white;
            border-radius: 0%;
            margin-top: 0px;
            animation: none;
            width: 100%;
            min-width: 6%;
            height: auto;
            opacity: 1;
            font-size: 14px;
            &:hover{
                background-color: #3F055B;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (max-width: 1280px) {
    .plans{
        .dropdown-menu{
            .dropdown-item{
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .plans{
        margin-top: 5%;
        margin-bottom: 5%;
        p{
            font-size: 16px;
        }
        h2{
            padding-top: 3%;
            font-size: 18px;
        }
        .dropdown-menu{
            .dropdown-item{
                font-size: 11px;
            }
        }
    }
  }


  @media only screen and (max-width: 700px) {
    .plans{
    #dropdown-basic-button{
        top: 80px;
    }
    .carousel-indicators{
        bottom: -10px;
    }
  }
}