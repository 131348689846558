@import './../../variables';

.gradien{
    background: linear-gradient(180deg, transparent 0%, rgba(11, 2, 16, 1) 15%, rgba(11, 2, 16, 1) 85%, transparent 100%);

    .location-container{
        position: relative;
        margin-top: $sidesMargin;
        padding: 0 10vw;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 90vh;
        background-image: url("../../img/stars_bg.png");

        .location-text-container{
            font-family: $font;
            font-style: normal;
            color: $Color-white;
            width: 46%;
            .location-over-title{
                font-size: 24px;
                font-weight: 700;
            }
            .location-title{
                font-size: 45px;
                font-weight: 800;
                text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
            }
            .location-text{
                font-size: 20px;
                font-weight: 400;
                .about-bold{
                    font-weight: 700;
                }
            }
            .about-location{
                color: #00CEC9;
                position: absolute;
                left: 18vh;
                top: 48vh;
                width: 50%;
                font-size: 20px;
            }
            .about-location-ms{
                color: #00CEC9;
                position: absolute;
                left: 30vh;
                top: 51vh;
                width: 50%;
                font-size: 22px;
                font-weight: bold;
            }
        }
    }

}
.about-container {
    margin-bottom: $sidesMargin;
    font-family: $font;
    font-style: normal;
    color: $Color-white;
    text-align: center;
    width: 100%;
    height: 90vh;
    background-image: url("../../img/stars_bg.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .about-title {
        font-size: 45px;
        font-weight: 800;
        margin-bottom: 20px;
        width: 73%;
    }
    .about-text {
        font-size: 20px;
        font-weight: 500;
        width: 73%;
        text-wrap: pretty;
        .about-bold{
            font-weight: 700;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1100px){
    .gradien{
        .location-container{
            img{
                width: 40%;
            }
            .location-text-container{
                .location-over-title{
                    font-size: 20px;
                }
                .location-title{
                    font-size: 30px;
                    margin-bottom: 10px;
                }
                .location-text{
                    font-size: 16px;
                }
                .about-location{
                    color: #00CEC9;
                    position: absolute;
                    left: 5vh;
                    top: 48vh;
                    width: 50%;
                    font-size: 18px;
                    .flagChile{
                        width: 30px;
                        height: auto;
                    }
                }
                .about-location-ms{
                    color: #00CEC9;
                    position: absolute;
                    left: 10vh;
                    top: 51vh;
                    width: 50%;
                    font-size: 15px;
                    font-weight: bold;
                }
            }
        }
    }

    .about-container{
        .about-title{
            font-size: 30px;
        }
        .about-text{
            font-size: 16px;
        }
        .rocket{
            width: 24px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .gradien{
        .location-container{
            flex-direction: column-reverse;
            justify-content: center;
            height: 100vh;
            img{
                width: 40%;
            }
            .location-text-container{
                width: 100%;
                .location-over-title{
                    font-size: 18px;

                }
                .location-title{
                    font-size: 24px;
                    margin-bottom: 5px;
                }
                .location-text{
                    font-size: 14px;
                }
                .about-location{
                    color: #00CEC9;
                    position: absolute;
                    left: 10vh;
                    top: 60vh;
                    width: 50%;
                    font-size: 18px;
                    .flagChile{
                        width: 30px;
                        height: auto;
                    }
                }
                .about-location-ms{
                    color: #00CEC9;
                    position: absolute;
                    left: 14vh;
                    top: 63vh;
                    width: 50%;
                    font-size: 15px;
                    font-weight: bold;
                }
            }
        }
    }

    .about-container{
        .about-title{
            font-size: 24px;
        }
        .about-text{
            font-size: 14px;
        }
        .rocket{
            width: 24px;
        }
    }
}

@media only screen and (max-width: 650px){
    .gradien{
        .location-container{
            .location-text-container{
                .about-location{
                    position: absolute;
                    transform: translate(100px,-50px);
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .about-container{
        .about-text{

        }
        .rocket{
            width: 16px;
        }
    }
    .gradien{
        .location-container{
            .location-text-container{
                .about-location{
                    transform: translate(50px,-50px);
                }
            }
        }
    }
}

@media only screen and (max-width: 500px){
    .gradien{
        .location-container{
            .location-text-container{
                .about-location{
                    transform: translate(20px,-30px);
                }
            }
        }
    }
}

/* aqui */
@media only screen and (max-width: 400px){
    .about-container{
        margin-bottom: 100px;
    }
    .gradien{
        .location-container{
            .location-text-container{
                .about-location{
                    transform: translate(20px,-10px);
                }
            }
        }
    }
}