.sidebarContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    gap: 5px;
    position: fixed;
    right: 0;
    height: auto;
    background-color: #29033B90;
    z-index: 10;
    top: 30vh;
    padding: 10px 0;
    a{
        height: auto;
        img{
            padding: 12px;
            opacity: 68%;
            width: 70px;
            height: auto;
            &:hover{opacity: 1;}
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .sidebarContainer{
        top: 20vh;
        a{
            img{
                width: 50px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {    .sidebarContainer{
    top: 20vh;
    a{
        img{
            width: 50px;
        }
    }
}}