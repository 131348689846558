@import './../../variables';

.container-benefits-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .benefits-title{
        font-family: $font;
        font-style: normal;
        font-weight: 800;
        font-size: 40.5px;
        color: $Color-white;
        text-align: center;
        margin: 5vw;
    }
    .benefits-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 $sidesMargin;
    }
}

@media only screen  and (max-width: 1100px) {
    .benefits-container{
        flex-direction: column;
    }
    .container-benefits-page{
        .benefits-title{
            font-size: 30px;
        }
    }
}
/* aqui */
@media only screen and (max-width: 400px){
    .container-benefits-page{
        margin-top: 100px;
    }
}