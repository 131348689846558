
/* Colors */
$Color-cyan : #00FFF9;
$Color-cyan-hover :#00CEC9E5;
$Color-purple : #00CEC9E5;
$Color-white : #ffffffe5;

/* Font */
$font: "Exo", sans-serif;

/* Margins */
$sidesMargin: 15vw;