@import './../../variables';

.banner {
    display: flex;
    width: 100%;
    height: 58px;
    background-color:  rgba(0, 206, 201, 1);
    justify-content: center;
    align-items: center;
    h2{
        font-family: $font;
        font-weight: 700;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        width: 90%;
    }
}

@media (max-width: 500px) {
    .banner{
        h2 {
            font-size: 14px;
        }
    }
}