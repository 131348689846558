@import './../../variables';

.containerCard {
    position: relative;
    width: 250px;
    /* width: 20%; */
    height: 459px;
    background: linear-gradient(180deg, #3e055b8a 0%, rgba(27, 9, 36, 0.9) 50.4%, #3e055b8a 100%);
    border: 3px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.65);
    border-radius: 5px;
    margin: 10px 0;
    transition: .3s;
    &:hover{
        border: #05BCBB 3px solid;
    }
    .containerCardBody {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .titleCard {
            color: $Color-white;
            font-family: $font;
            font-weight: 800;
            font-size: 29px;
            text-align: center;
            margin-top: 0;
        }
        .titlePack{
            color: $Color-white;
            font-family: $font;
            font-weight: 400;
            font-size: 28px;
            text-align: center;
            margin-bottom: 0;
        }
        .textRam{
            color: $Color-white;
            font-family: $font;
            font-weight: 700;
            font-size: 40px;
            text-align: center;
            span {
                font-weight: 400;
            }
        }
        .textPrice{
            color: $Color-cyan;
            font-family: $font;
            font-weight: 600;
            font-size: 46px;
            text-align: center;
            width: 206px;
            height: 73px;
        }
        .textBodyContainer{
            width: 181px;
            height: 32px;
            .textBody{
                color: $Color-white;
                font-family: $font;
                font-weight: 400;
                font-size: 12px;
                text-align: center;
                margin: 0;
            }
        }

        .prominentLabel{
            background-color: #0E8C40;
            border-radius: 20px;
            padding: 5px 10px;
            font-weight: 600;
            font-size: 10px;
            color: white;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%)
        }

    }
}

.prominentCard{
    border: #F5F5F5 3px Solid;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 3px 3px 15px 2px #edfcfb40, -3px -3px 15px 2px #00CEC940;
    &:hover {
        box-shadow: 3px 3px 15px 2px #00CEC960, -3px -3px 15px 2px #00CEC960;
    }
}


@media only screen and (max-width: 1600px){
    .containerCard {
        width: 25%;
    }
}

@media only screen and (max-width: 1461px){
    .containerCard {
        .containerCardBody{
            .textRam{
                font-size: 36px;
            }
            .textPrice{
                font-size: 46px;
            }
        }
    }
}

@media only screen and (max-width: 1290px){
        .gameListRow{
            margin: 0 !important;
            justify-items: center;
            .containerCard{
                width: 60%;
            }
        }
}


  @media only screen and (min-width: 768px) and (max-width: 1000px) {
    .containerCard {
        width: 80% !important;
        height: 459px;
        .containerCardBody{
            .titleCard {
                font-size: 20px;
            }
            .titlePack {
                font-size: 18px;
            }
            .textRam{
                font-size: 30px;
            }
            .textPrice{
                font-size: 40px;
            }
            .textBodyContainer{
                width: 165px;
            }
        }

    }

  }


@media only screen and (max-width: 768px) {
    .containerCard {
        width: 100% !important;
        padding: 10px;
        height: 130px;
        .containerCardBody{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            .titleCard {
                font-size: 16px;
            }
            .titlePack {
                font-size: 14px;
            }
            .textRam{
                font-size: 14px;
            }
            .textPrice{
                font-size: 16px;
                width: auto;
                height: auto;
            }
            .textBodyContainer{
                width: 120px;
                .textBody{
                    font-size: 11px;
                }
            }
        }
        .prominentLabel{
            top: 5%;
        }
    }
}

@media only screen and (max-width: 700px) {
    .containerCard {
        width: 200px !important;
        padding: 10px;
        height: 320px;
        .containerCardBody{
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            .titleCard {
                font-size: 16px;
            }
            .titlePack {
                font-size: 14px;
            }
            .textRam{
                font-size: 14px;
                margin-bottom: 10px;
            }
            .textPrice{
                font-size: 16px;
                width: auto;
                height: auto;
            }
            .textBodyContainer{
                .textBody{
                    font-size: 11px;
                }
                margin-bottom: 30px;
            }
        }
        .prominentLabel{
            display: none;
        }
    }
}
