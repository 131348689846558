@import './../../variables';

.carouselPlansContainer{
    padding-top: 3%;
    position: relative;
    font-family: $font;
    .gameListRow{
        padding-top: 3%;
    }
    .game-list-container {
        width: 100%; 
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3vw;
        .titlePlan{
            font-family: $font;
            font-style: normal;
            font-weight: 800;
            font-size: 38.7px;
            color: $Color-white;
            text-align: center;
        }
    }
    .carousel-indicators{
        button{
            width: 15px;
            height: 15px;
            border: $Color-cyan;
            border-radius: 50px !important;
            margin: 0 5px;

        }
        .active{
            background-color: $Color-cyan;
            color: $Color-cyan;
        
        }
    }

    .carousel-control-next{
        width: auto;
        height: fit-content;
        position: absolute;
        top: 50%;
        right: 7%;
        .carousel-control-next-icon{
            background-image: url("../../img/carousel/carousel-next.svg");
            
        }
    }
    .carousel-control-prev{
        width: auto;
        height: fit-content;
        position: absolute;
        top: 50%;
        left: 7%;
        .carousel-control-prev-icon{
            background-image: url("../../img/carousel/carousel-prev.svg");
        }
    }

}

